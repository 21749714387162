import React, { createContext, JSXElementConstructor, ReactElement, ReactNode, useContext, useEffect, useMemo, useState } from "react";

import './NavigationBar.css';
import '../../../App.css';

export type NavigationOptions = {
    title: string;
    link: string;
    onClick: () => void;
};

type NavigationEntryWrapperProps = {
    title: string;
    options?: NavigationOptions[];
    onClick?: () => void;
};

const NavigationEntryWrapper = ({title, options, onClick}: NavigationEntryWrapperProps) => {
    
    return (
        <NavigationEntry
            title={title}
            options={options}
            className='test'
            entryPosition={0}
            onClick={onClick}
        />
    );
};

type Dimensions = {
    width: number;
    height: number;
}
const DimensionsContext = createContext<Dimensions>({width: window.innerWidth, height: window.innerWidth});
interface NavigationEntryProps extends NavigationEntryWrapperProps {
        className: string;
        dimensions?: {width: number, height: number};
        entryPosition: number;
}
const NavigationExpandableEntry = ({title, options, className, entryPosition}: NavigationEntryProps) => {
    const dimensionsContext = useContext(DimensionsContext);
    const [hover, setHover] = useState<boolean>(false);
    const [optionElements, setOptionElements] = useState<ReactNode[]>([]);
    const [optionsStyle, setOptionsStyle] = useState({});

    useEffect(() => {
        const tempOptionElements = [];

        if (options) {
            for (let i = 0; i < options?.length; i++) {
                tempOptionElements.push(<div className={`expandable-option \
                ${options.length - 1 === i ? 'last-entry' : ''}`}
                onClick={options[i].onClick}>{options[i].title}</div>)
            }
            setOptionElements(tempOptionElements);
        }
    }, []);

    useEffect(() => {
        if(hover) {
            const position = ((dimensionsContext!.width/window.screen.availWidth) + (600/dimensionsContext!.width ) * 2);
            setOptionsStyle({
                top: `${position}rem`,
                left: `${entryPosition*17}%`,
                boxShadow: '5px 7px 4px -3px',
                zIndex: 3,
                transition: 'top 0.8s ease, box-shadow 3s ease .2s, z-index 2s ease .1s'
            });
        } else {
            setOptionsStyle({
                top: '-40%',
                left: `${entryPosition*17}%`,
                boxShadow: '0px 0px 0px 0px',
                zIndex: -1,
                transition: 'top 1s 0.1s ease, box-shadow 0.2s ease, z-index 0s ease'
            });
        }
    }, [hover, dimensionsContext.width, dimensionsContext, entryPosition])

    return (
        <div
            id={`navigation-entry-${title}`}
            className={`${className}`}
            onMouseEnter={() => {setHover(true)}}
            onMouseLeave={() => {setHover(false)}}
        >
            <div className="expandable-container">
                <header className="noselect">{title}</header>
                <div className={`expandable-arrow ${hover ? 'expandable-arrow-hover' : ''}`}/>
            </div>
            <div className="expandable-options-list" style={optionsStyle}>
                {optionElements}
            </div>
        </div>
    );
};

const NavigationEntry = ({title, className, onClick}: NavigationEntryProps) => {

    return (
        <div
            id={`navigation-entry-${title}`}
            className={`${className}`}
            onClick={onClick}
        >
            <header className="noselect">{title}</header>
        </div>
    );
};

type NavigationBarProps = {
    children: ReactElement<NavigationEntryProps, string | JSXElementConstructor<NavigationEntryProps>>[];
}
const NavigationBar = ({children}: NavigationBarProps) => {
    const dimensionsContext = useContext(DimensionsContext);
    const [navigationEntries, setNavigationEntries] = useState<undefined | ReactNode[]>(undefined);
    const [hover, setHover] = useState<boolean>(false);
    const [containerStyle, setContainerStyle] = useState({});
    const [dimensions, setDimensions] = React.useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    const handleResize = () => {
        setDimensions({
            width: window.innerWidth,
            height: window.innerHeight,
        });

        dimensionsContext.width = window.innerWidth;
        dimensionsContext.height = window.innerHeight;
      }
    React.useEffect(() => {
      window.addEventListener("resize", handleResize, false);
    }, []);

    useEffect(() => {
        if (hover) {
            setContainerStyle({height: `${(25000 / dimensions.width) + 20}px`})
        } else {
            setContainerStyle({height: `${(25000 / dimensions.width) + 5}px`})
        }

    }, [dimensions, hover])
    
    useMemo(() => {
        const tempChildren = [];
        if (children && children.length) {
            for (let i = 0; i < children.length; i++) {
                if (children[i].props.options) {
                    tempChildren.push(
                        <DimensionsContext.Provider value={dimensionsContext}>
                            <NavigationExpandableEntry
                                key={`navigation-entry-expand-${i}`}
                                {...children[i].props}
                                dimensions={dimensions}
                                entryPosition={i}
                                className={`navigation-entry-container ${i === 0 ? 'first-entry' : ''}`}
                            />
                        </DimensionsContext.Provider>
                    )
                } else {
                    tempChildren.push(
                        <NavigationEntry
                            key={`navigation-entry-${i}`}
                            {...children[i].props}
                            className={`navigation-entry-container ${i === 0 ? 'first-entry' : ''}`}
                        />
                    )
                }
            }
            setNavigationEntries(tempChildren);
        }
    }, [])

    return (
    <div
        id='navigation-container'
        style={containerStyle}
        onMouseEnter={() => {setHover(true)}}
        onMouseLeave={() => {setHover(false)}}
    >
        {navigationEntries}
    </div>
    )
}

export {NavigationBar, NavigationEntryWrapper as NavigationEntry}