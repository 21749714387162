// import logo from './logo.svg';
import React from 'react';
import './UnderConstruction.css';

function UnderConstruction() {
  return (
    <div className="under-construction">
      <header className="under-construction-header">
        <div>
          <div className="under-construction-warning-triangle">
              <div className="under-construction-warning-triangle-exclamation noselect">!</div>
          </div>
          <p>
            Under Construction
          </p>
        </div>
      </header>
    </div>
  );
}

export default UnderConstruction;
