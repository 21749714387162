import './App.css';
import Resume from './components/Resume';
import {NavigationBar, NavigationEntry} from './components/utilities/NavigationBar/NavigationBar';
import UnderConstruction from './components/utilities/UnderConstruction/UnderConstruction';
import React, { useEffect, useRef, useState } from 'react';

type StorageObject = {
  selectedPage: string;
}

const getLocalStorage: (setSelectedComponent: React.Dispatch<string>) => StorageObject =
  (setSelectedComponent: React.Dispatch<string>) => {
    const storageObject = localStorage.getItem("siteStorage");

    if (storageObject){
      const parsedStorageObject = JSON.parse(storageObject) as StorageObject;
      setSelectedComponent(parsedStorageObject.selectedPage);
      return parsedStorageObject;
    }

    setSelectedComponent('resume');
    return { selectedPage: 'resume' };
  }

const displayComponent = (selectedComponent: string) => {
  switch(selectedComponent) {
    case 'resume':
      return (<Resume/>);
    default:
      return (<UnderConstruction/>)
  }
}
const selectPage = (
  {storageObject, setFunction, option}:
  {storageObject: Record<string, any>, setFunction: React.Dispatch<any>, option: string}
) => {
  storageObject.selectedPage = option;
  localStorage.setItem("siteStorage", JSON.stringify(storageObject))
  setFunction(option);
}

function App() {
  const [selectedComponent, setSelectedComponent] = useState<string>('');
  let storageObject = useRef<StorageObject>({selectedPage: ''}) ;
  useEffect(() => {storageObject.current = getLocalStorage(setSelectedComponent)}, [])

  return (
    <div className="App">
      <NavigationBar>
        <NavigationEntry
            title='Resume'
            onClick={() => selectPage({storageObject, setFunction: setSelectedComponent, option: 'resume'})}
          />
        <NavigationEntry
          title='Examples'
          options={[
            {title: 'Blog', link: 'testing', onClick: () => selectPage({storageObject, setFunction: setSelectedComponent, option: 'blog'})}
          ]}
        />
        <NavigationEntry
            title='GitHub'
            onClick={() => window!.open('https://github.com/Sujeom', '_blank')!.focus()}
          />
        <NavigationEntry
            title='LinkedIn'
            onClick={() => window!.open('https://www.linkedin.com/in/josue-martinez-65b03496/', '_blank')!.focus()}
          />
      </NavigationBar>
      <div id='application-main-section' className='hide-scrollbar'>
        {displayComponent(selectedComponent)}
      </div>
    </div>
  );
}

export default App;
