// import logo from './logo.svg';
import React from 'react';
import './WorkInProgress.css';
import '../../../App.css';

function WorkInProgress() {
  console.log('test')
  return (
    <div className="work-in-progress">
        <div className='text-entry noselect'>
            WIP
        </div>
    </div>
  );
}

export default WorkInProgress;
