// import logo from './logo.svg';
import React, { useEffect } from 'react';
import './Resume.css';
import '../App.css';
import WorkInProgress from './utilities/WorkInProgress/WorkInProgess';

function Resume() {
  // useEffect(() => {
  //   window.addEventListener('scroll', () => {
  //     document.body.style.setProperty('--scroll',window.scrollY / (document.body.offsetHeight - window.innerHeight));
  //   }, false);

  //   return () => {window.removeEventListener('scroll', () => {})}
  // }, [])
  useEffect(() => {
    window.addEventListener(
      "scroll",
      () => {
        document.body.style.setProperty(
          "--scroll",
          (window.scrollY / (document.body.offsetHeight - window.innerHeight)) as unknown as string
        );
      },
      false
    );
  }, [])
  return (
    <div className="Resume">
      <WorkInProgress/>
      <div id="resume-spinner-wheel">
        <div className='resume-spinner-text first noselect'>Contact Information</div>
        <div className='resume-spinner-text second noselect'>Work Experience</div>
        <div className='resume-spinner-text third noselect'>Projects</div>
        <div className='resume-spinner-text fourth noselect'>Skills</div>
      </div>
      <h1 className="resume-header">Josue Martinez</h1>

      <div id="contact-information resume-separater">
        <p className='resume-separater'>
          Martinez344j@gmail.com ❖ Orlando, FL
        </p>
      </div>
      <div id="work-experience">
        <h3 className='resume-separater '>Work Experience</h3>
        <div>
          <div className='resume-company'>
            <h4 className='resume-company-tag'>Lockheed Martin</h4>
            <h4 className='resume-duration-tag'>May 2018-Present</h4>
            <h4 className='resume-position-tag'>Software Engineer</h4>
            <h4 className='resume-location-tag'>Orlando, FL</h4>
          </div>
        </div>
        <div className='resume-bullet'>
          ▪ 
          <p className='resume-description'>
            Lead the development of the JASSM Mission Planning frontend a Cloud-based Cesium RESTful application, 
            constructed with a ReactJS interface leveraging the Patternfly framework, alongside a middleware application 
            running on Node.js for communication and client session management. With a focus on cloud readiness for future 
            iterations of Mission Planning, I prioritized containerization to facilitate a smooth transition to cloud 
            infrastructure,while also incorporating technologies like Electron for standalone execution on Windows and 
            Linux systems. Testing was comprehensive, incorporating the Mocha framework, c8, and react-testing-library for 
            achieving 100% code coverage, and utilizing Selenium through Mocha for workflow testing to detect and address any 
            significant bugs. Lighthouse was employed to ensure adherence to web performance and design standards, thus 
            ensuring an optimal user experience. Additionally, I established a robust pipeline for the project, maintaining 
            100% code coverage and ensuring compliance with workflow and web standards throughout development.
          </p>
        </div>
        <div className='resume-bullet'>
          ▪ 
          <p className='resume-description'>
            Led the design and development of a Python-based RESTful API, utilizing Gunicorn as the WSGI server to 
            efficiently route requests to the Flask framework. This robust framework encompasses crucial routing 
            functionalities such as Port Management, VLANs, Multicast, and QoS. Additionally, I spearheaded the design 
            of the ReactJS frontend, leveraging the Patternfly framework to deliver users an immersive interface, 
            facilitating seamless interaction with the backend system.
          </p>
        </div>
        <div className='resume-bullet'>
          ▪ 
          <p className='resume-description'>
            Designed a ReactJS frontend plugin for Cockpit, streamlining the utilization of the Lockheed Martin 
            Hardened Security Hypervisor. This initiative fostered a strategic partnership with RedHat, leading to 
            certification on RHEL systems.
          </p>
        </div>
        <div className='resume-bullet'>
          ▪ 
          <p className='resume-description'>
            Led a 5G effort by setting up Ansible playbooks that would set up a Linux environment with an instance of 
            a GitLab, a Harbor registry and pushes hardened base images from Platform One Iron Bank.
          </p>
        </div>
        <div className='resume-bullet'>
          ▪ 
          <p className='resume-description'>
            Designed demonstrations incorporating Orchestration platforms such as Kubernetes and Docker-compose 
            deploying containers such as mySQL, Grafana, and cache stressing application, demonstration system 
            improvements using Intel Select Solutions with Lockheed Martin
          </p>
        </div>
        <div className='resume-bullet'>
          ▪ 
          <p className='resume-description'>
            Contributed significantly to the F-35 maintenance (ALIS) software modernization project by migrating a 
            Java application to operate on Linux, containerizing the Java application, and establishing robust build 
            pipelines. Built containers were then deployed Through RedHat Openshift.
          </p>
        </div>
      </div>
      <div id="resume-projects">
        <p className='resume-separater'>
          Projects
        </p>
        <div className='resume-bullet'>
          ▪ 
          <p className='resume-description project'>
            JASSM Planning Frontend — MFC JASSM Mission Planning
          </p>
        </div>
        <div className='resume-bullet'>
          ▪ 
          <p className='resume-description project'>
            OAP Networking — MFC Processor Tech Center
          </p>
        </div>
        <div className='resume-bullet'>
          ▪ 
          <p className='resume-description project'>
            LMHS — Intel Select Solutions with Lockheed Martin
          </p>
        </div>
        <div className='resume-bullet'>
          ▪ 
          <p className='resume-description project'>
            ALIS — F-35 Maintenance System
          </p>
        </div>
      </div>
      <div id="resume-education">
        <p className='resume-separater'>
          Education
        </p>
        <div>
          <div className='resume-company education'>
            <h4 className='resume-company-tag'>University of Central Florida</h4>
            <h4 className='resume-duration-tag'>Graduation August 2018</h4>
            <h4 className='resume-position-tag'>BS in Computer Science</h4>
            <h4 className='resume-location-tag'>Orlando, FL</h4>
          </div>
        </div>
      </div>
      <div id="resume-skills-awards">
        <p className='resume-separater'>
          SKILLS & AWARDS
        </p>
        <div>
          <div className='resume-bullet'>
            ▪ 
            <p className='resume-description skills-awards'>
              <div className='resume-skills-awards'>
                Skills:
              </div>
              <div>
                JavaScript, Python, Java, HTML, Cascading Style Sheets (CSS), Ansible, Linux Development,
                DevSecOps, SQL, Docker, Orchestration, Kubernetes, Containers, Agile Methodologies, Git, GitLab, JIRA,
                RESTful, React.js, Flask, TypeScript
              </div>
            </p>
          </div>
          <div className='resume-bullet'>
          ▪ 
          <p className='resume-description skills-awards'>
            <div className='resume-skills-awards'>
              Awards:
            </div>
            <div>
              NOVA award 2020, Excellence Award 2020.
            </div>
          </p>
        </div>
        </div>
      </div>
    </div>
  );
}

export default Resume;
